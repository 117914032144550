export const dashTranslations = {
  spanish: {
    dashboard: {
      kpis: {
        title: "KPI's",
        items: [
          {
            alias: 'Bloques de alta prioridad',
            content: 'Cantidad de bloques atrasados y disponibles a ejecutar el día de hoy en ruta crítica.'
          },
          {
            alias: 'Días de atraso',
            content: 'Días de atraso generados por los desplazamientos en la obra.'
          },
          {
            aliasLeft: 'Avance real',
            aliasRight: 'Programado',
            contentLeft: 'Avance que se ha logrado en la obra hasta el momento actual.',
            contentRight: 'Avance que se espera que tenga la obra en el momento actual.'
          },
          {
            alias: 'Finalización a ritmo actual',
            content: 'Fecha de finalización de la obra a ritmo de trabajo actual.'
          },
          {
            alias: 'Fecha de finalización',
            content: 'Fecha de finalización de la obra para la etiqueta seleccionada.'
          },
          {
            alias: 'Finalización a máximo esfuerzo',
            content: 'Fecha de finalización de la obra para la etiqueta seleccionada.'
          }
        ]
      },
      chart: {
        title: 'Avance general de obra'
      },
      specialityAdv: {
        title: 'Avance por especialidad'
      }
    }
  },
  english: {
    dashboard: {
      kpis: {
        title: "KPI's",
        items: [
          {
            alias: 'High priority blocks',
            content: 'Number of delayed and available blocks to be executed today on the critical path.'
          },
          {
            alias: 'Delayed days',
            content: 'Delay days due to displacements at work.'
          },
          {
            aliasLeft: 'Real progress',
            aliasRight: 'Scheduled',
            contentLeft: 'Progress which has been achieved at work to date.',
            contentRight: 'Progress that the work is expected to have at present.'
          },
          {
            alias: 'Completion at current pace',
            content: 'Completion date of the work at current work rate.'
          },
          {
            alias: 'Completion date',
            content: 'Completion date of the work for the selected tag.'
          },
          {
            alias: 'Completion at max effort',
            content: 'Completion date of the work for the selected tag.'
          }
        ]
      },
      chart: {
        title: 'Work general progress'
      },
      specialityAdv: {
        title: 'Advancement by specialty'
      }
    }
  }
}

export const dtTranslations = {
  spanish: {
    filter: {
      action: 'Filtrar',
      header: 'Filtre por etiquetas los bloques que desea visualizar',
      dates: {
        title: 'Fechas',
        from: 'Desde',
        to: 'Hasta'
      },
      titles: ['Especialidades', 'Fases', 'Niveles', 'Otras'],
      buttons: [
        'Limpiar',
        'Filtrar'
      ]
    },
    sequence: {
      action: 'Secuencia'
    },
    specialties: {
      action: 'Especialidad',
      header: 'Seleccione las especialidades que desea visualizar',
      onClose: [
        'Cerrar',
        'Seleccionar'
      ]
    },
    visibility: {
      action: 'Estado',
      onClose: [
        'Cerrar',
        'Seleccionar'
      ]
    },
    modelSelection: {
      action: 'Nube',
      title: 'Seleccione el modelo que desea ver',
      buttons: [
        'Descargar',
        'Aplicar'
      ]
    },
    localModelSelection: {
      action: 'Local',
      title: 'Seleccione el modelo que desea ver',
      buttons: [
        'Cargar',
        'Aplicar'
      ],
      placeholder: 'Cargá un modelo local'
    }
  },
  english: {
    filter: {
      action: 'Filter',
      header: 'Filter by tags the blocks you want to visualize',
      dates: {
        title: 'Dates',
        from: 'From',
        to: 'To'
      },
      titles: ['Specialties', 'Phases', 'Contractors', 'Others'],
      buttons: [
        'Clear',
        'Filter'
      ]
    },
    sequence: {
      action: 'Sequence'
    },
    specialties: {
      action: 'Specialty',
      header: 'Select the specialties you want to visualize',
      onClose: [
        'Close',
        'Select'
      ]
    },
    visibility: {
      action: 'Status',
      onClose: [
        'Close',
        'Select'
      ]
    },
    modelSelection: {
      action: 'Cloud',
      title: 'Select the model you want to see',
      buttons: [
        'Download',
        'Apply'
      ]
    },
    localModelSelection: {
      action: 'Local',
      title: 'Select the model you want to see',
      buttons: [
        'Load',
        'Apply'
      ],
      placeholder: 'Load a local model'
    }
  }
}

export const blockModalTranslations = {
  spanish: {
    title: [
      'Bloque',
      'Descripción'
    ],
    table: {
      item_1: 'Fecha programada',
      item_2: 'Fecha de reprogramación',
      item_3: 'Fecha de ejecución',
      item_4: 'Atraso en días',
      item_5: 'Ejecución física',
      item_6: 'GUID'
    }
  },
  english: {
    title: [
      'Block',
      'Description'
    ],
    table: {
      item_1: 'Scheduled date',
      item_2: 'Rescheduled date',
      item_3: 'Executed date',
      item_4: 'Delay days',
      item_5: 'Physical execution',
      item_6: 'GUID'
    }
  }
}

export const dependenciesModalTranslations = {
  spanish: {
    title: 'Dependencias',
    noData: 'No existen dependencias sin ejecutar para este bloque',
    error: 'Hubo un error buscando las dependencias de este bloque',
    treeTitle: 'Diagrama de dependencias'
  },
  english: {
    title: 'Dependencies',
    noData: 'No dependencies to execute for this block',
    error: 'There was an error searching for dependencies for this block',
    treeTitle: 'Dependency diagram'
  }
}

export const worktableTranslations = {
  spanish: {
    export: {
      action: 'Exportar tabla'
    }
  },
  english: {
    export: {
      action: 'Export table'
    }
  }
}
